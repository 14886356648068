import "./polyfill";
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";

import React              from "react";
import App                from "./App";
import { Provider }       from "react-redux";
import store              from "./redux/store";
import { icons }          from "./assets/icons";
import { ToastContainer } from "react-toastify";
import * as serviceWorker from "./serviceWorker";
import { createRoot }     from 'react-dom/client';
import ApiContext         from "./containers/ApiContext";
import "./ReactotronConfig";

React.icons = icons;

const root = createRoot(document.getElementById("root"));

const Main = () => (
  <Provider store={store}>
    <ApiContext>
      <ToastContainer stackable />
      <App />
    </ApiContext>
  </Provider>
);

root.render(<Main />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
