const initialState = {
  isLoading: false,
  options: [],
  available: [],
  selected: null,
  categories: [],
  selectedCategories: null,
  newStoreForm: {},
  currentCityStores: [],
  showPhoneNumberModal: false,
  showPhoneNumber2FAModal: false
};

const storesReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "setNewStoreFormData":
      return { ...state, newStoreForm: { ...state.newStoreForm, [rest.key]: rest.value } };

    case "setAvailableStores":
      return { ...state, available: rest.storeList };

    case "setSelectedStores":
      return { ...state, selected: rest.selectedList };

    case "setStoresCategories":
      return { ...state, categories: rest.categoryList };

    case "setSelectedCategories":
      return { ...state, selectedCategories: rest.category };

    case "setResetSelectedCategories":
      return { ...state, selectedCategories: [] };

    case "setCurrentCityStores":
      return { ...state, currentCityStores: rest.storeList };
    
    case "setShowPhoneNumberModal":
      return { ...state, showPhoneNumberModal: rest.isVisible };
    
    case "setShowPhoneNumber2FAModal":
      return { ...state, showPhoneNumber2FAModal: rest.isVisible };

    default:
      return state;
  }
};

export default storesReducer;
