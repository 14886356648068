import { toast } from "react-toastify";
import api, { api2, headers } from "../index";

export default class userRequests {
  constructor(onLoading, onError) {
    this.setLoading            = boolean => onLoading(boolean);
    this.handleError           = error => onError(error);
    this.postUsers             = this.postUsers.bind(this);
    this.getUser               = this.getUser.bind(this);
    this.putUser               = this.putUser.bind(this);
    this.getMyNodes            = this.getMyNodes.bind(this);
    this.putUserPhoneNumber    = this.putUserPhoneNumber.bind(this);
    this.getUserPhoneNumber2FA = this.getUserPhoneNumber2FA.bind(this);
    this.putUserPhoneNumber2FA = this.putUserPhoneNumber2FA.bind(this);
  }

  async postUsers(payload) {
    try {
      this.setLoading(true);
      return await api2.post("/user/create", payload);
    } catch (error) {
      toast.error(error.response.data.errors[0], {
        autoClose: false,
        position: "top-right"
      });
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async getUser({ userId }) {
    try {
      this.setLoading(true);
      const url = `/user/${userId}`;
      const response = await api.get(url, headers());
      return response.data;
    } catch (error) {
      return this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async putUser({ userId, data }) {
    try {
      this.setLoading(true);
      const url = `/user/${userId}`;
      const response = await api.put(url, data, headers());
      return response.data;
    } catch (error) {
      return this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async getMyNodes(userId) {
    try {
      this.setLoading(true);
      const response = await api.get(`/user/myNodes/${userId}`, headers());
      return response.data;
    } catch (error) {
      this.handleError(error);
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async putUserPhoneNumber({ userEmail, data, setLoading }) {
    try {
      setLoading(true);
      const url = `/user/phone-number/${userEmail}`;
      return await api2.put(url, data, headers());
    } catch (error) {
      return this.handleError(error);
    } finally {
      setLoading(false);
    }
  }

  async getUserPhoneNumber2FA({ userEmail, setLoading }) {
    try {
      setLoading(true);
      const url = `/user/2fa/${userEmail}`;
      return await api2.put(url, {}, headers());
    } catch (error) {
      return this.handleError(error);
    } finally {
      setLoading(false);
    }
  }

  async putUserPhoneNumber2FA({ userEmail, token, setLoading }) {
    try {
      setLoading(true);
      const url = `/user/confirm-account/${userEmail}/${token}`;
      return await api2.put(url, {}, headers());
    } catch (error) {
      return this.handleError(error);
    } finally {
      setLoading(false);
    }
  }
}
