import { api2, headers } from "../index";
import Swal              from "sweetalert2";

export default class storeRequests {
  constructor(onLoading, onError) {
    this.setLoading = boolean => onLoading(boolean);
    this.handleError = error => onError(error);
    this.postStore = this.postStore.bind(this);
    this.getStores = this.getStores.bind(this);
    this.putStore = this.putStore.bind(this);
    this.deleteStore = this.deleteStore.bind(this);
    this.getStoreCategories = this.getStoreCategories.bind(this);
    this.getStoreByCityName = this.getStoreByCityName.bind(this);
  }

  async getStoreByCityName(stateSigla, cityName, page = 1) {
    try {
      this.setLoading(true);
      const url = `/store/${stateSigla}/${cityName}?page=${page}`;
      return await api2.get(url);
    } catch (error) {
      if ("No stores found" === error.response.data.message) {
        return { data: { stores: [] } };
      } else {
        this.handleError(error);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async getStoreCategories() {
    try {
      this.setLoading(true);
      return await api2.get("/category/all");
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async getStores() {
    try {
      this.setLoading(true);
      const url = "/store";
      return await api2.get(url);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async postStore(payload) {
    try {
      this.setLoading(true);
      const url = "/store/create";
      return await api2.post(url, payload);
    } catch (error) {
      Swal.fire({
        title: "Atenção!",
        icon: "error",
        text: error.response.data.errors.join(", "),
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Ok",
      });
    } finally {
      this.setLoading(false);
    }
  }

  async getStoresFromUser(userId) {
    try {
      const url = `/store/user/${userId}`;
      return await api2.get(url, headers());
    } catch (error) {
      console.log(error);
      return { data: { stores: [] } };
    }
  }

  async putStore({storeId, payload}) {
    try {
      this.setLoading(true);
      const url = `/store/update/${storeId}`;
      const response = await api2.put(url, payload, headers());
      Swal.fire({
        title: response.data.status === 200 ? "Sucesso!" : "Atenção!",
        icon: response.data.status === 200 ? "success" : "warning",
        text: response.data.message,
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: "Beleza!",
      }).then(() => {
        if (response.data.status === 200) {
          window.location.push("/minhas-lojas");
        }
      });
      return response;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async deleteStore(storeId) {
    try {
      this.setLoading(true);
      const url = `/store/delete/${storeId}`;
      return await api2.delete(url, headers());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }
}
