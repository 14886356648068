import {
  CButton,
  CCol,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CRow,
} from "@coreui/react";
import React, { useContext, useEffect, useRef } from "react";
import Swal                                     from "sweetalert2";
import Card                                     from "../../../components/Card";
import { FaLock }                               from "react-icons/fa";
import { useHistory, useParams }                from "react-router-dom";
import { apiContext }                           from "../../../containers/ApiContext";

export default function RecoverPassword() {
  const params = useParams();
  const history = useHistory();
  const { putNewPassword } = useContext(apiContext);
  const passwordInputRef = useRef(null);
  const passwordConfirmInputRef = useRef(null);

  useEffect(() => {
    if (!params?.token) history.push("/");
    if (!params?.email || !params?.email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/))
      history.push("/");
  }, []);

  async function submitForm() {
    const password = passwordInputRef.current.value;
    if (password && password === passwordConfirmInputRef.current.value) {
      const putNewPasswordResponse = await putNewPassword({ email: params.email, password, token: params.token });
      if (putNewPasswordResponse?.status === 200) {
        await Swal.fire({
          title: "Sucesso!",
          text: putNewPasswordResponse.data.message,
          icon: "success",
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: "Fechar",
        });
        history.push("/");
      }
    } else {
      Swal.fire({
        title: "Erro!",
        text: "As senhas informadas devem ser iguais.",
        icon: "error",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "Fechar",
      });
    }
  }

  return (
    <CContainer>
      <CRow className="d-flex justify-content-center">
        <CCol xs="12" sm="10" md="8" lg="6" style={{ marginTop: "20vh" }}>
          <Card icon="fas fa-lock" label="Recuperar senha">
            <CRow>
              <CCol xs={12}>
                <CLabel htmlFor="new-password-input">
                  <h5>Digite sua nova senha</h5>
                </CLabel>
                <CInputGroup>
                  <CInputGroupPrepend>
                    <CInputGroupText><FaLock /></CInputGroupText>
                  </CInputGroupPrepend>
                  <CInput
                    size="lg"
                    type="password"
                    name="password-input"
                    innerRef={passwordInputRef}
                    id="new-password-input"
                    placeholder="Nova senha"
                  />
                </CInputGroup>
              </CCol>
              <CCol xs={12} className="mt-4">
                <CInputGroup>
                  <CInputGroupPrepend>
                    <CInputGroupText><FaLock /></CInputGroupText>
                  </CInputGroupPrepend>
                  <CInput
                    size="lg"
                    type="password"
                    name="password-input"
                    innerRef={passwordConfirmInputRef}
                    id="new-password-input"
                    placeholder="Nova senha"
                  />
                  <CInputGroupAppend>
                    <CButton color="primary" onClick={submitForm}>
                      Enviar
                      <i className="fas fa-paper-plane ml-2 icon-fix" />
                    </CButton>
                  </CInputGroupAppend>
                </CInputGroup>
              </CCol>
            </CRow>
          </Card>
        </CCol>
      </CRow>
    </CContainer>
  );
}
