import { applyMiddleware, createStore, compose } from "redux";
import thunk                                     from "redux-thunk";
import Reducers                                  from "./reducers";
import reactotron                                from "../ReactotronConfig"; // Importe o reactotron

const middleware = applyMiddleware(thunk);

const reduxDevToolsOptions = { trace: true, traceLimit: 25 };

// Use o Reactotron na criação do store com o compose
const composer =
  process.env.NODE_ENV === "development"
    ? createStore(
      Reducers,
      compose(
        middleware,
        window.__REDUX_DEVTOOLS_EXTENSION__
          ? window.__REDUX_DEVTOOLS_EXTENSION__(reduxDevToolsOptions)
          : (f) => f,
        reactotron.createEnhancer() // Adiciona o enhancer do Reactotron
      )
    )
    : createStore(Reducers, compose(middleware, reactotron.createEnhancer()));

const store = composer;

export default store;